<template>
  <el-container class="patroladd">
     
    <el-header class="box add bb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
       <el-breadcrumb-item>系统参数配置</el-breadcrumb-item>
       <el-breadcrumb-item >区域页面参数模板列表</el-breadcrumb-item>
        <el-breadcrumb-item v-if="formInline.page_config_region_id">编辑区域页面参数模板</el-breadcrumb-item>
         <el-breadcrumb-item v-else>新增区域页面参数模板</el-breadcrumb-item>
    </el-breadcrumb>
        <div>
             <el-button  class=""  size="medium" type="primary" @click="submitForm('formInline')">提交</el-button>
            <el-button  class="back_btn" size="medium" plain @click="$router.back()">返回</el-button></div>
    </el-header>
    <el-main>
    <el-form :inline="true"  status-icon ref="formInline"  :model="formInline"  class="demo-ruleForm">
     <el-row class="box mt20  f14">
         <el-col :span="24" class="patroladd-title bgcfbe tl mb20 pl15">基础信息</el-col>
        <el-col :span="11" class="tl">
        <el-form-item label="绑定模板：" class="w" label-width="28%" prop="page_config_name" :rules="[{required: true,message: '请绑定模板', trigger: ['blur','change']}]">
          <el-input v-model="formInline.page_config_name" size="medium" disabled>
            <el-button slot="append" icon="el-icon-search" @click="handleChooseCompany">请选择模板</el-button>
          </el-input>
        </el-form-item>
        </el-col>
        <el-col :span="22" class="tl">
           <el-row class="box mt20 f14">
              <el-form-item class="w" label-width="14%" label="使用区域：" size="medium">
                <el-col :span="4">
                  <el-form-item size="medium">
                    <el-select v-model="provinceTemp" clearable placeholder="请选择省级" @change="handleChange($event,2)" value-key="region_name" >
                      <el-option  v-for="item in provinceOptions" :key="item.region_code" :label="item.region_name" :value="item" ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item size="medium">
                    <el-select v-model="cityTemp" clearable  placeholder="请选择市级" @change="handleChange($event,3)"  value-key="region_name">
                      <el-option  v-for="item in cityOptions" :key="item.region_code" :label="item.region_name"  :value="item" ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item size="medium">
                    <el-select v-model="areaTemp" clearable placeholder="请选择区/县" @change="handleChange($event,4)" value-key="region_name" >
                      <el-option v-for="item in areaOptions" :key="item.region_code" :label="item.region_name" :value="item" ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item size="medium">
                    <el-select v-model="townTemp" clearable placeholder="请选择街道/镇"  @change="handleChange($event,5)" value-key="region_name"  >
                      <el-option v-for="item in townOptions"  :key="item.region_code" :label="item.region_name" :value="item" ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item size="medium">
                    <el-select v-model="villTemp" clearable placeholder="请选择居委会/村" value-key="region_name" @change="handleChange($event,6)" >
                      <el-option v-for="item in villOptions" :key="item.region_code" :label="item.region_name" :value="item" ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-form-item>
            </el-row>
        </el-col>
     </el-row>
        </el-form>
        <el-dialog title="企业列表" :visible.sync="dialogTableVisible" @close="onClose">
        <el-row class="box search mt20" :gutter="10">
           <el-form :inline="true" :model="page" class="demo-form-inline">
        <el-col :span="4" class="mr10">
             <el-form-item size="medium" >
         <el-input placeholder="页面参数分类名称" clearable v-model="page.page_module_name" class="input-with-select">
            </el-input>
        </el-form-item>
        </el-col>
         <el-col :span="4" class="mr10">
            <el-form-item size="medium">
              <el-select v-model="page.state" clearable placeholder="全部" @change="onSubmit(page.state)">
                <el-option
                  v-for="(item,index) in statusOptions"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
         <el-col :span="2" class="tl mr10">
           <el-form-item  size="medium">
          <el-button  type="primary"  @click="onSubmit" class="general_bgc general_border cfff">查询</el-button>
        </el-form-item>
        </el-col>
       
      </el-form>
        </el-row>
        <el-table border :data="companyData" style="width: 100%">
           <el-table-column label="选择" width="60" header-align="center" align="center">
            <template slot-scope="scope">
              <el-radio class="radio" v-model="selected" :label="scope.$index"></el-radio>
            </template>
          </el-table-column>
        <el-table-column type="index" align='center' width="60"  label="序号"></el-table-column>
        
         <el-table-column prop="page_config_name" min-width="120" :show-overflow-tooltip="true"  label="模板名称" align='center'></el-table-column>
         <el-table-column prop="company_tag_name"  min-width="150" :show-overflow-tooltip="true"  label="主体类型" align='center'></el-table-column>
          <el-table-column align="center" label="状态">
            <template slot-scope="scope">
              <span class="f12" :class="scope.row.state===1?'c67c':'ce6a'"
              >{{scope.row.state | statusFilter}}</span>
            </template>
          </el-table-column>
        <el-table-column prop="page_module_name"  min-width="120" :show-overflow-tooltip="true" label="页面参数分类名称" align='center'>
        </el-table-column>
         </el-table>
      <div class="i-page fr disib mt20">
        <el-pagination
          background
          :page-size='10'
          layout="total,prev, pager, next"
          :total="total"
          :current-page="currentPage"
           @current-change = 'currentChange'>
        </el-pagination>
      </div>
        <div slot="footer" class="dialog-footer" style="text-align:center;margin-top:50px">
          <el-button @click="dialogTableVisible = false">取 消</el-button>
          <el-button type="primary" @click="handleConfirm()">确 定</el-button>
        </div>
      </el-dialog>
        </el-main>
  </el-container>
</template>
<script>
let obj;
import Vue from 'vue'
import axios from '../../../util/api'
import md5 from 'js-md5';
import { local,objKeySort,remove,session } from '../../../util/util'
export default {
  data () {
    return {
        formInline: {
          page_config_region_id:'',
          company_type_id:'',
          company_type_name:'',
          company_tag_id:'',
          company_tag_name:"",
          page_module_id:"",
          page_module_name:'',
         page_config_name:'',
         page_config_id:'',
        province:'',
         city:'',
         area:'',
          town:'',
         vill:'',
         page_region_content:[]
      },
      page: {
        pager_offset: '0',
        pager_openset:'10',
       companytype_code:'',
       companytag_code:'',
       page_config_name:'',
       state:'',
      },
      page_module:'',
      user:'',
      region_high_code:'',
      provinceTemp: "",
      cityTemp: "",
      areaTemp: "",
      townTemp: "",
      villTemp: "",
      provinceOptions: [],
      cityOptions: [],
      areaOptions: [],
      townOptions: [],
      villOptions: [],
       dialogTableVisible: false,
       companyData: [],
        currentPage: 1,
      total: 0,
      selected: "",
      companyListLoading: false,
      list_btn:[{id:'1001574645421581111111101',title: '四川省小作坊登记证'},{id:'1001574645421581111111102',title: '四川省小经营店登记证（流通）'},{id:'1001574645421581111111103',title: '食品经营许可（餐饮）'},{id:'1001574645421581111111104',title: '四川省小经营店登记证（餐饮）'},{title: '食品经营许可证（流通）',id:'1001574645421581111111105'}],
       statusOptions: [
        { label: "全部", value: "" },
        { label: "启用", value: "1" },
        { label: "禁用", value: "2" }
      ],
      userData:{}
    }
  },
  filters: {
    statusFilter(status) {
      const statusMap = {
        1: "已启用",
        2: "已禁用"
      };
      return statusMap[status];
    },
    tagFilter(status) {
      const statusMap = {
        1: "success",
        2: "warning"
      };
      return statusMap[status];
    },
  },
  mounted() {
    
  },
     created () {
         this.user = local.get('user');
          this.userData = local.get("user");
          this.formInline.page_config_region_id =session.get('params').id?String(session.get('params').id):'';
     this.init()
  },
  methods: {
      init () {
       if(this.formInline.page_config_region_id){
           axios.get('/pc/page-config-region/single', {page_config_region_id:this.formInline.page_config_region_id}).then((v) => {
       this.formInline=v.data.page_config_region;
       this.handleUserLevel(this.userData,this.formInline);
     })
       }else{
       this.handleUserLevel(this.userData,'');}

    },
     handleChooseCompany() {
      this.dialogTableVisible = true;
      this.initCompany(this.page);
    },
     initCompany(params) {
      this.companyListLoading = true;
      axios.get("/pc/page-config/list", params).then(response => {
        this.companyData = response.data.page_config_list;
        this.total = response.data.pager_count;
        this.companyListLoading = false;
      });
    },
      userTypeList3(row){
       if(row.companytag_code=='1001574645421581111111103'){return '食品经营许可（餐饮）';}else if(row.companytag_code=='1001574645421581111111104'){return '四川省小经营店登记证（餐饮）';}else if(row.companytag_code=='1001574645421581111111101'){return '四川省小作坊登记证';}else if(row.companytag_code=='1001574645421581111111102'){return '四川省小经营店登记证（流通）';}else if(row.companytag_code=='1001574645421581111111105'){return '食品经营许可证（流通）';}
    },
     change3(val) {this.page.state=val;this.onSubmit();},
    change1(val){this.page.companytag_code=val;this.onSubmit();},
        change2(val){if(val==''||val==null){val='1'}this.page.owner_type=val;this.onSubmit();},
     // 搜索
     onSubmit () { 
      this.currentPage = 1
      this.page.pager_offset = '0'
      this.initCompany(this.page);
    },
    // 分页
    currentChange(page){
      this.currentPage = page;
      this.page.pager_offset = String((page - 1) * 10)
      this.initCompany(this.page);
    },
        onClose() {
      this.$refs["formInline"].validateField("page_config_name");
    },
    handleConfirm() {
      let item = this.companyData[this.selected];
     this.formInline.company_type_id = String(item.company_type_id);
     this.formInline.company_type_name = item.company_type_name;
     this.formInline.company_tag_id = String(item.company_tag_id);
     this.formInline.company_tag_name = item.company_tag_name;
     this.formInline.page_module_id = String(item.page_module_id);
     this.formInline.page_module_name = item.page_module_name;
     this.formInline.page_config_name = item.page_config_name;
     this.formInline.page_config_id = String(item.page_config_id);
      this.formInline.page_region_content = item.page_config_content;
      this.dialogTableVisible = false;
    },
     submitForm(formInline){
      this.formInline.province = this.provinceTemp.region_name;
      this.formInline.city = this.cityTemp.region_name;
      this.formInline.area = this.areaTemp.region_name;
      this.formInline.town = this.townTemp.region_name;
      this.formInline.vill = this.villTemp.region_name;
          let paramas=this.formInline;
          let paramas1={
             page_config_region_id:String(this.formInline.page_config_region_id),
             company_type_id :String(paramas.company_type_id),
             company_tag_id:String(paramas.company_tag_id),
             page_module_id:String(paramas.page_module_id),
             page_config_id:String(paramas.page_config_id),
             page_config_name:paramas.page_config_name,
          }
         this.$refs[formInline].validate((valid) => {
        if (valid) {
             axios.get("/pc/page-config-region/exist", paramas1).then(v => {//验证查询监管分类是否存在
            if(this.formInline.page_config_region_id){
              axios.put('/pc/page-config-region/update', paramas).then((v) => {
                this.$router.back()
              })
            }else{
              axios.post('/pc/page-config-region/save', paramas).then((v) => {
                this.$router.back()
              })
            } 
           
          });
                 
        }else{
           this.$message.error('请检查表单必填项');
            return false
        }
         })
      },
      async handleUserLevel(userData, company) {
      if (parseInt(userData.user_type) > 0 || company.province) {
        this.provinceOptions = await this.fetchRegionData(1);
        let province = this.provinceOptions.find(
          item =>item.region_name ==(userData.user_province ?userData.user_province :company.province  )
        );
        this.provinceTemp = province;
       
        if (parseInt(userData.user_type) >= 1 || company.city) {
          this.cityOptions = await this.fetchRegionData(2,province.region_code);
          let city = this.cityOptions.find(item =>
              item.region_name ==(userData.user_city ? userData.user_city : company.city)
          );
          if (parseInt(userData.user_type) >= 1) this.provinceFlag = true;
          if (parseInt(userData.user_type) >= 2 || company.area) {
            this.areaOptions = await this.fetchRegionData(3, city.region_code);
            let area = this.areaOptions.find(
              item => item.region_name ==(userData.user_area ? userData.user_area : company.area)
            );
            this.cityTemp = city;
            if (parseInt(userData.user_type) >= 2) this.cityFlag = true;
            if (parseInt(userData.user_type) >= 3 || company.town) {
              this.townOptions = await this.fetchRegionData(4,area.region_code);
              let town = this.townOptions.find(
                item =>item.region_name ==(userData.user_town ? userData.user_town : company.town)
              );
              this.areaTemp = area;
              if (parseInt(userData.user_type) >= 3) this.areaFlag = true;
              if (parseInt(userData.user_type) >= 4 || company.vill) {
                this.villOptions = await this.fetchRegionData(5, town.region_code);
                let vill = this.villOptions.find(
                  item =>item.region_name ==(userData.user_vill ? userData.user_vill : company.vill)
                );
                this.townTemp = town;
                if (parseInt(userData.user_type) >= 4) this.townFlag = true;
                if (parseInt(userData.user_type) >= 5 || company.vill) {
                  this.villTemp = vill;
                  if (parseInt(userData.user_type) >= 5) this.villFlag = true;
                }
              }
            }
          }
        }
      }
    },
    fetchRegionData(type, region_high_code) {
      let region_type = type.toString();
      return new Promise((resolve, reject) => {
        axios
          .get("/pc/region/all", { region_type, region_high_code })
          .then(response => {
            resolve(response.data.region_list);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    async handleChange(item, type) {
         let  regionData;
     if(item.region_code==undefined){
        regionData=''
     }else{
       regionData =  await this.fetchRegionData(type, item.region_code);
     }
      switch (type) {
        case 2:
          this.cityOptions = regionData;
           this.formInline.province = item.region_name;
          this.areaOptions = [];
          this.townOptions = [];
          this.villOptions = [];
          this.cityTemp = "";
          this.areaTemp = "";
          this.townTemp = "";
          this.villTemp = "";
          break;
        case 3:
          this.areaOptions = regionData;
           this.formInline.city = item.region_name;
          this.townOptions = [];
          this.villOptions = [];
          this.areaTemp = "";
          this.townTemp = "";
          this.villTemp = "";
          break;
        case 4:
          this.townOptions = regionData;
           this.formInline.area = item.region_name;
          this.villOptions = [];
          this.townTemp = "";
          this.villTemp = "";
          break;
        case 5:
          this.villOptions = regionData;
          this.formInline.town = item.region_name;
          this.villTemp = "";
          break;
        case 6:
          this.formInline.vill = item.region_name;
          break;
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.patroladd{
  overflow-x: hidden;
  .add{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .el-image{width: 160px; height: 160px;margin-right: 10px}
  .back_btn{background:rgba(247,248,249,1);border:1px solid rgba(217,222,225,1);}
  .patroladd-title{height: 34px;line-height: 34px;border-left: 2px solid #788287}
  /deep/ .el-form--inline .el-col-12 .el-form-item__content{width: 75%}
  /deep/ .el-radio__label {
    display: none !important;
  }
  /deep/ .el-dialog__body {
    padding-top: 5px;
    padding-bottom: 10px;
  }
  /deep/ .el-input.is-disabled .el-input__inner {
    background-color: #ffffff;
    border-color: #e4e7ed;
    color: #606266;
    cursor: not-allowed;
  }
  /deep/ .el-dialog {
    width: 60% !important;
  }
}

</style>
